import { apiClient } from "../../api-client"

export class DoctorApi {
  static async getDoctorList(params = {}) {
    const { data } = await apiClient.get("doctors", {
      params,
    })

    return data
  }

  static async getDoctorById(id) {
    const { data } = await apiClient.get(`doctors/${id}`)

    return data.data
  }

  static async createDoctor(data) {
    const response = await apiClient.post("doctors", data)

    return response.data
  }

  static async updateDoctor(id, data) {
    const response = await apiClient.put(`doctors/${id}`, data)

    return response.data
  }

  static async deleteDoctor(id) {
    const response = await apiClient.delete(`doctors/${id}`)

    return response.data
  }
  static async deleteDoctorMany(ids) {
    const response = await apiClient.delete("doctors", {
      data: { ids },
    })

    return response.data
  }
}
