import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

export const usePageCurrent = () => {
  const [urlParams, setUrlParams] = useSearchParams()

  const page = useMemo(() => Number(urlParams.get("page") || 1), [urlParams])

  const setPage = (page) =>
    setUrlParams((prev) => {
      prev.set("page", page)
      return prev
    })

  return { page, setPage }
}
