// import { useSetUserData } from "../hooks/use-set-user-data"
import { useUserQuery } from "../queries/use-user-query"
import { Loading } from "../../../components/loading"
import { useDispatch } from "react-redux"
import { setUser } from "../store"
import { useEffect } from "react"
import { useSetUserData } from "../hooks/use-set-user-data"

/**
 * @param {import('react').PropsWithChildren} props
 * @returns {import('react').ReactNode}
 */
export const AuthenticationInitializer = ({ children }) => {
  const setUserData = useSetUserData()

  const dispatch = useDispatch()

  const { data, isPending, isSuccess } = useUserQuery()

  // if (isSuccess && data) {
  //   console.log(data)

  //   dispatch(setUser(data.data))
  // }
  useEffect(() => {
    if (isSuccess && data) {
      // console.log('init auth',data.data)
      dispatch(setUser(data.data))
      setUserData(data.data)
    }
  }, [isSuccess, data, dispatch])

  if (isPending) {
    return <Loading />
  }

  return <>{children}</>
}
