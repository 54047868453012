import React from "react"
import { Modal } from "antd"

const DeletePatientModal = ({
  isModalOpen,
  onOkClick,
  onCancelClick,
  message,
}) => {
  return (
    <>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={onOkClick}
        onCancel={onCancelClick}
        footer={() => (
          <div>
            <button onClick={onCancelClick} className="btn btn-outline-dark">
              Cancel
            </button>
            <button onClick={onOkClick} className="ms-2 btn btn-danger">
              Delete
            </button>
          </div>
        )}
      >
        <p>{message}</p>
      </Modal>
    </>
  )
}

export default DeletePatientModal
