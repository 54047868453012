import React from "react"
import ReactDOM from "react-dom/client"
import Approuter from "./approuter"

import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/style.css"
import "./assets/css/select2.min.css"
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css"
import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(<Approuter />)
