import React, { useState } from "react"
import { useFormik } from "formik"
import { DatePicker } from "antd"
import { useTranslation } from "react-i18next"
import { DoctorApi } from "../api"
import { useNavigate } from "react-router-dom"

// const validationSchema = Yup.object({
//   first_name: Yup.string().required("First Name is required"),
//   last_name: Yup.string().required("Last Name is required"),
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   password: Yup.string().required("Password is required"),
//   password_confirmation: Yup.string()
//     .oneOf([Yup.ref("password"), null], "Passwords must match")
//     .required("Password confirmation is required"),
//   date_of_birth: Yup.string().required("Date of Birth is required"),
//   specialization: Yup.string().required("Specialization is required"),
//   registration_number: Yup.string().required("Registration number is required"),
//   address: Yup.string().nullable(),
//   city: Yup.string().nullable(),
// })

export const AddDoctorForm = () => {
  const { t } = useTranslation()
  const [isClicked, setIsClicked] = useState(false)
const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      address: "",
      city: "ddd",
      date_of_birth: "",
      specialization: "",
      registration_number: "",
      phone_number: "",
    },
    // validationSchema, // Yup validation schema
    onSubmit: async (values) => {
      try {
        await DoctorApi.createDoctor(values) // Submit form data to API

navigate('/doctorlist')      } catch (error) {
        console.error("Error adding doctor:", error)
        alert("Failed to add doctor")
      }
    },
  })

  const onChangeDate = (date, dateString) => {
    setIsClicked(true)
    formik.setFieldValue("date_of_birth", dateString) // Set date in Formik
  }



  return (
    <div className="card">
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-heading">
              <h4>{t("Add Doctor")}</h4>
            </div>
          </div>

          {/* First Name */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                First Name <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="error">{formik.errors.first_name}</div>
              ) : null}
            </div>
          </div>

          {/* Last Name */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Last Name <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="error">{formik.errors.last_name}</div>
              ) : null}
            </div>
          </div>

          {/* Email */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Email <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Phone number <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="phone_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone_number}
              />
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <div className="error">{formik.errors.phone_number}</div>
              ) : null}
            </div>
          </div>

          {/* Password */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Password <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>

          {/* Confirm Password */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Confirm Password <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                name="password_confirmation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password_confirmation}
              />
              {formik.touched.password_confirmation &&
              formik.errors.password_confirmation ? (
                <div className="error">
                  {formik.errors.password_confirmation}
                </div>
              ) : null}
            </div>
          </div>

          {/* Date of Birth */}
          <div className="col-12">
            <div className="form-group local-forms cal-icon">
              <label>
                Date Of Birth <span className="login-danger">*</span>
              </label>
              <DatePicker
                className="form-control datetimepicker"
                onChange={onChangeDate}
                suffixIcon={null}
                style={{
                  borderColor: isClicked
                    ? "#2E37A4"
                    : "rgba(46, 55, 164, 0.1)",
                }}
              />
              {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                <div className="error">{formik.errors.date_of_birth}</div>
              ) : null}
            </div>
          </div>

          {/* Specialization */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Specialization <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="specialization"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.specialization}
              />
              {formik.touched.specialization &&
              formik.errors.specialization ? (
                <div className="error">{formik.errors.specialization}</div>
              ) : null}
            </div>
          </div>

          {/* Registration Number */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>
                Registration number <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="registration_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.registration_number}
              />
              {formik.touched.registration_number &&
              formik.errors.registration_number ? (
                <div className="error">
                  {formik.errors.registration_number}
                </div>
              ) : null}
            </div>
          </div>

          {/* Address */}
          <div className="col-12">
            <div className="form-group local-forms">
              <label>Address</label>
              <textarea
                className="form-control"
                name="address"
                rows={3}
                cols={30}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-12">
            <div className="doctor-submit text-end">
              <button
                type="submit"
                className="btn btn-primary submit-form me-2"
              >
                Submit
              </button>
              <button type="reset" className="btn btn-secondary cancel-form">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  )
}
