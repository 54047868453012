/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react"
import { Table } from "antd"
import { itemRender } from "../../components/Pagination"
import { refreshicon, searchnormal } from "../../components/imagepath"
import { useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import ConfirmDeleteModel from "../../components/ConfirmDeleteModel"
import { usePageCurrent } from "../../components/doctor/usePageCurrent"
import { DashboardLayout } from "../../layouts/DashboardLayout"
import { omitUndefinedAndNull } from "../../utils/omitUndefinedAndNull"
import { ContactApi } from "../../features/contact/api"
import { contactKeys } from "../../features/contact/query-keys"

const ContactList = () => {
  const { t } = useTranslation()

  const [urlParams, setUrlParams] = useSearchParams()

  const { page } = usePageCurrent()

  const sortOptions = useMemo(() => {
    const sortBy = urlParams.get("sortBy") || "created_at"
    const order = urlParams.get("order") || "asc"
    const type = urlParams.get("type")

    return {
      sortBy,
      order,
    }
  }, [urlParams])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isDeleteManyModalOpen, setIsDeleteManyModalOpen] = useState(false)

  const [recordIdToDelete, setRecordIdToDelete] = useState(null)

  const [typeQuery, setSearchQuery] = useState(urlParams.get("type"))

  const { data, isPending, isError, refetch, isRefetching } = useQuery({
    queryKey: contactKeys.getFiltered({
      page,
      per_page: 10,
      sortBy: sortOptions.sortBy,
      order: sortOptions.order,
      type: urlParams.get("type"),
    }),
    queryFn: async () =>
      await ContactApi.getContactList(
        omitUndefinedAndNull({
          page,
          per_page: 10,
          sort_by: sortOptions.sortBy,
          sort_order: sortOptions.order,
          type: urlParams.get("type"),
        })
      ),
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (id) => await ContactApi.deleteContact(id),
    onSuccess: () => refetch(),
  })

  //delete many contact
  const { mutateAsync: deleteManyAsync } = useMutation({
    mutationFn: async (ids) => await ContactApi.deleteContactMany(ids),
    onSuccess: () => {
      refetch()
      setSelectedRowKeys([])
    },
  })
  console.log(data)
  /**
   * @type {import("antd").TableProps<any>.columns} columns
   */
  const columns = [
    {
      title: t("Last name"),
      dataIndex: "last_name",
      render: (text, record) => (
        <>
          <span>{record.user.last_name}</span>
        </>
      ),
    },
    {
      title: t("First name"),
      dataIndex: "first_name",
      render: (text, record) => (
        <>
          <span>{record.user.first_name}</span>
        </>
      ),
      // sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: t("Email"),
      dataIndex: "email",
      render: (text, record) => (
        <>
          <span>{record.user.email}</span>
        </>
      ),
    },
    {
      title: t("Type"),
      dataIndex: "type",
      render: (text, record) => (
        <>
          <span>{record.type}</span>
        </>
      ),
    },
    {
      title: t("Message"),
      dataIndex: "message",
      render: (text, record) => (
        <>
          <span> {record.message} </span>
        </>
      ),
    },
    {
      title: t("Action"),
      render: (text, record) => (
        <div className=" justify-content-between align-items-center mt-3 w-28">
          <button
            className="btn btn-danger me-2"
            onClick={() => {
              setRecordIdToDelete(record.id)
              setIsModalOpen(true)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <g className="trash-outline">
                <g
                  fill="currentColor"
                  fillRule="evenodd"
                  className="Vector"
                  clipRule="evenodd"
                >
                  <path d="M4.917 6.003a1 1 0 0 1 1.08.914l.849 10.248A2 2 0 0 0 8.839 19h6.322a2 2 0 0 0 1.993-1.835l.85-10.248a1 1 0 0 1 1.993.166l-.85 10.247A4 4 0 0 1 15.162 21H8.84a4 4 0 0 1-3.987-3.67l-.85-10.247a1 1 0 0 1 .914-1.08"></path>
                  <path d="M3 7a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1m7 2a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1"></path>
                  <path d="M10.441 5a1 1 0 0 0-.948.684l-.544 1.632a1 1 0 1 1-1.898-.632l.544-1.633A3 3 0 0 1 10.441 3h3.117a3 3 0 0 1 2.846 2.051l.545 1.633a1 1 0 0 1-1.898.632l-.544-1.632A1 1 0 0 0 13.56 5h-3.117Z"></path>
                </g>
              </g>
            </svg>
          </button>
          <Link to={`/show/${record.id}`} className="btn btn-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M14 12c-1.095 0-2-.905-2-2c0-.354.103-.683.268-.973C12.178 9.02 12.092 9 12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3s3-1.358 3-3c0-.092-.02-.178-.027-.268c-.29.165-.619.268-.973.268"
              ></path>
              <path
                fill="currentColor"
                d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316l-.105-.316C21.927 11.617 19.633 5 12 5m0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5c-.504 1.158-2.578 5-7.926 5"
              ></path>
            </svg>
          </Link>
        </div>
      ),
    },
  ]

  useEffect(() => {
    const nextBtn = document.querySelector(".ant-pagination-next")

    if (nextBtn) {
      nextBtn.classList.add("me-3")
    }
  }, [])

  const [tableParams, setTableParams] = useState({
    pagination: {
      total: data?.meta?.total,
      pageSize: data?.meta?.per_page,
      current: page,
      showTotal: (total, range) =>
        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
      showSizeChanger: false,
      itemRender: itemRender,
    },
    sortOrder: sortOptions.order,
    sortField: sortOptions.sortBy,
  })

  useEffect(
    () => {
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: data?.meta?.total,
          pageSize: data?.meta?.per_page,
          current: page,
        },
      }))
    } /* eslint-disable-next-line */,
    [data]
  )

  /**
   *
   * @type {import("antd").TableProps<DataType>['onChange']} handleTableChange
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const sortBy = sorter.field || "created_at"
    const order = sorter.order || "desc"

    setTableParams({
      pagination,
      filters,
      sortOrder: order,
      sortField: sortBy,
    })

    setUrlParams((prev) => {
      prev.set("page", pagination.current)
      prev.set("sortBy", sortBy)
      prev.set("order", order === "descend" ? "asc" : "desc")
      return prev
    })
  }

  useEffect(() => {
    refetch() // Fetch the data again when URL params change
  }, [urlParams, refetch])

  if (isError) {
    return <p>Failed to load message list.</p>
  }
  return (
    <>
      {/* Table Header */}
      <div className="page-table-header mb-2">
        <div className="row align-items-center">
          <div className="col">
            <div className="doctor-table-blk">
              <h3>Messages List</h3>
              <div className="doctor-search-blk">
                <div className="top-nav-search table-search-blk">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      // Update the URL params when the form is submitted
                      setUrlParams((prev) => ({
                        ...prev,
                        type: typeQuery,
                        page: 1, // Reset to page 1 when searching
                      }))
                    }}
                  >
                    <select
                      className="form-control"
                      value={typeQuery}
                      onChange={(e) => {
                        const selectedValue = e.target.value

                        if (selectedValue === "") {
                          setUrlParams((prev) => {
                            prev.delete("type")
                            prev.set("page", 1)
                            return prev
                          })
                        } else {
                          setUrlParams((prev) => {
                            prev.set("type", selectedValue)
                            prev.set("page", 1) // Optional: reset page if needed
                            return prev
                          })
                        }

                        setSearchQuery(selectedValue)
                      }}
                    >
                      <option value="">Select type</option>
                      <option value="bug">Bug</option>
                      <option value="feature">Feature</option>
                      <option value="test">Test</option>
                    </select>

                    <button type="submit" className="btn">
                      <img src={searchnormal} alt="Search" />
                    </button>
                  </form>
                </div>
                <div className="add-group">
                  {/* <Link
                    to="/add-contact"
                    className="btn btn-primary add-pluss ms-2"
                  >
                    <img src={plusicon} alt="#" />
                  </Link> */}

                  <button
                    className={"btn btn-primary contact-refresh ms-2"}
                    disabled={isRefetching}
                    onClick={() => refetch()}
                  >
                    <img
                      className={`${isRefetching ? "rotate-animation" : ""}`}
                      src={refreshicon}
                      alt="#"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto text-end float-end ms-auto add-group">
            <button
              disabled={
                isPending || isRefetching || selectedRowKeys.length === 0
              }
              className={"btn btn-danger contact-refresh ms-2"}
              onClick={() => {
                if (selectedRowKeys.length) {
                  setIsDeleteManyModalOpen(true)
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="m18 9l-.84 8.398c-.127 1.273-.19 1.909-.48 2.39a2.5 2.5 0 0 1-1.075.973C15.098 21 14.46 21 13.18 21h-2.36c-1.279 0-1.918 0-2.425-.24a2.5 2.5 0 0 1-1.076-.973c-.288-.48-.352-1.116-.48-2.389L6 9m7.5 6.5v-5m-3 5v-5m-6-4h4.615m0 0l.386-2.672c.112-.486.516-.828.98-.828h3.038c.464 0 .867.342.98.828l.386 2.672m-5.77 0h5.77m0 0H19.5"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* /Table Header */}
      <div className="table-responsive contact-list">
        <Table
          columns={columns}
          dataSource={data?.data}
          loading={isPending}
          pagination={tableParams.pagination}
          // pagination={{
          //   total: data?.meta?.total,
          //   pageSize: data?.meta?.per_page,
          //   current: page,
          //   showTotal: (total, range) =>
          //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          //   showSizeChanger: false,
          //   itemRender: itemRender,
          //   onChange: setPage,
          // }}
          onChange={handleTableChange}
          rowSelection={{
            selectedRowKeys,
            onSelect: (_, __, selectedRows) => {
              setSelectedRowKeys(selectedRows.map((row) => row.id))
            },
          }}
          rowKey={({ id }) => id}
          style={{
            backgroundColor: "#f2f2f2", // Replace with your desired background color for the table
          }}
        />
      </div>

      {/* Delete Many Model */}
      <ConfirmDeleteModel
        message={t("Are you sure you want to delete this contact?")}
        isModalOpen={isDeleteManyModalOpen}
        onOkClick={async () => {
          await deleteManyAsync(selectedRowKeys)
          setIsDeleteManyModalOpen(false)
        }}
        onCancelClick={() => {
          setIsDeleteManyModalOpen(false)
        }}
      />
      {/* /Delete Model */}

      {/* Delete Model */}
      <ConfirmDeleteModel
        message={t("Are you sure you want to delete this contact?")}
        isModalOpen={isModalOpen}
        onOkClick={async () => {
          await mutateAsync(recordIdToDelete)
          setRecordIdToDelete(null)
          setIsModalOpen(false)
        }}
        onCancelClick={() => {
          setRecordIdToDelete(null)
          setIsModalOpen(false)
        }}
      />
      {/* /Delete Model */}
    </>
  )
}

const Inbox = () => {
  return (
    <DashboardLayout>
      <div className="content">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Messages </Link>
                </li>
                <li className="breadcrumb-item">
                  <i className="feather-chevron-right">
                    <FeatherIcon icon="chevron-right" />
                  </i>
                </li>
                <li className="breadcrumb-item active">Messages List</li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table show-entire">
              <div className="card-body">
                <ContactList />

                <div className="notification-box">
                  <div className="msg-sidebar notifications msg-noti">
                    <div className="topnav-dropdown-header">
                      <span>Messages</span>
                    </div>
                    <div className="drop-scroll msg-list-scroll" id="msg_list">
                      <ul className="list-box">
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">R</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Richard Miles{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item new-message">
                              <div className="list-left">
                                <span className="avatar">J</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">John Doe</span>
                                <span className="message-time">1 Aug</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">T</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Tarah Shropshire{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">M</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Mike Litorus
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">C</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Catherine Manseau{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">D</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Domenic Houston{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">B</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Buster Wigton{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">R</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Rolland Webber{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">C</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  {" "}
                                  Claire Mapes{" "}
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">M</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Melita Faucher
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">J</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Jeffery Lalor
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">L</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Loren Gatlin
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="list-item">
                              <div className="list-left">
                                <span className="avatar">T</span>
                              </div>
                              <div className="list-body">
                                <span className="message-author">
                                  Tarah Shropshire
                                </span>
                                <span className="message-time">12:28 AM</span>
                                <div className="clearfix" />
                                <span className="message-content">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="topnav-dropdown-footer">
                      <Link to="#">See all messages</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Inbox
