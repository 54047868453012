import { apiClient } from "../../../api-client"

export class AuthApi {
  /**
   *
   * @param {{
   *  email: string
   *  password: string
   *  auth_provider: string
   * }} credentials
   * @returns {Promise}
   */
  static async login(credentials) {
    const { data } = await apiClient.post("login", credentials)

    return data
  }

  static async logout() {
    await apiClient.post("logout")
  }
}
