import React, { useEffect, useRef } from "react"
import ApexCharts from "apexcharts"

const ActivityChart = ({ data }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    const chartElement = chartRef.current

    if (chartElement) {
      const sColStackedOptions = {
        chart: {
          height: 230,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 6,
          colors: ["transparent"],
        },
        series: [
          {
            name: "Patients",
            color: "#D5D7ED",
            data: data?.patients,
          },
          {
            name: "Doctors",
            color: "#2E37A4",
            data: data?.doctors,
          },
        ],
        xaxis: {
          categories: data?.months,
        },
      }

      const chart = new ApexCharts(chartElement, sColStackedOptions)

      try {
        chart.render()
      } catch (error) {
        console.error("Error rendering chart:", error)
      }

      return () => {
        chart.destroy()
      }
    }
  }, [])

  return <div id="activity-chart" ref={chartRef}></div>
}

export default ActivityChart
